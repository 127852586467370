import { Injectable } from '@angular/core';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    CommonRegion, CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { ISaveDesignResult } from '@profis-engineering/pe-ui-common/entities/save-design';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    ApplicationState, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';

import { ApplicationType } from '../../shared/entities/code-lists/application-type';
import { ConnectionType } from '../../shared/entities/code-lists/connection-type';
import { DesignMethodGroup } from '../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import { convertStringToLatestDesign, IDisplayDesign } from '../../shared/entities/display-design';
import { DesignType as DesignTypeId } from '../../shared/entities/tracking-data';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    ConnectionType as ConnectionTypeId
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { getUKDesignStandardKey } from '../../shared/helpers/design-standard-methods-helper';
import { getSpriteAsIconStyle } from '../sprites';
import { CalculationServiceC2C } from './calculation-c2c.service';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentServiceC2C } from './document.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { RoutingService } from './routing.service';
import { TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
@Injectable({
    providedIn: 'root'
})
export class DesignService {
    constructor(
        private localization: LocalizationService,
        private codelist: CodeListService,
        private documentService: DocumentServiceC2C,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private designTemplateService: DesignTemplateService,
        private routingService: RoutingService,
        private calculationService: CalculationServiceC2C,
        private trackingService: TrackingService,
        private numberService: NumberService,
        private commonCodeList: CommonCodeListService,
        private featureVisibilityService: FeaturesVisibilityService,
    ) { }

    private get designType() {
        return this.codelist.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C].find((designType) => designType.id == DesignTypeId.Concrete2Concrete);
    }

    public getNewDesignName(isEntryPointSplittedPIR: boolean) {
        let translatedNameText = this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignTitle.Concrete2ConcretePirEU');
        if (!isEntryPointSplittedPIR) {
            translatedNameText = this.designType?.getTranslatedNameText(getCodeListTextDeps(this.localization)) ?? '';
        }

        return `${translatedNameText} - ${this.localization.moment(new Date()).format('ll')}`;
    }

    public applyToAllDesigns(userSettings: UserSettingsService, codeList: CodeListService, designStandardId?: number): boolean {
        if (userSettings.settings.quickStart.concrete2Concrete.applicationState.value != ApplicationState.SelectedConnection ||
            userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value == null ||
            designStandardId == undefined) {
            return false;
        }

        const selectedConnectionType = (codeList.projectCodeListsC2C[ProjectCodeList.ConnectionTypesC2C] as ConnectionType[])
            .find((item) => item.id == userSettings.settings.application.concrete2Concrete.selectedConnectionTypeId.value);

        const applicationTypes = codeList.projectCodeListsC2C[ProjectCodeList.ApplicationTypesC2C] as ApplicationType[];
        const availableConnections = selectedConnectionType?.applicationTypes?.map(id => {
            return applicationTypes.find(x => x.id == id);
        })
            .filter(appType => appType?.designStandards?.some(id => id == designStandardId)) ?? [];

        if (availableConnections.length > 0 && userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value != null) {
            return availableConnections.find((appType) => appType?.id == userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value) != null;
        }
        else if (availableConnections.length == 0 && userSettings.settings.application.concrete2Concrete.selectedApplicationTypeId.value == null) {
            return selectedConnectionType != null;
        }

        return false;
    }

    public openFromDocumentDesign(documentDesign: IDesignListItem) {
        documentDesign.projectName = this.documentService.findProjectById(documentDesign.projectId).name ?? '';

        return this.documentService.openDesignExclusiveC2C(documentDesign)
            .then((projectDesign) => this.calculationService.openFromProjectDesignC2C(projectDesign, documentDesign.id))
            .then((result) => {
                this.documentService.updateDesignWithNewContentC2C(result.design.id, result.design.projectId, result.design.designName, result.design.designData.projectDesignC2C, result.design.metaData, undefined, false, false, DocumentAccessMode.Open)
                    .finally(() => {
                        this.userService.changeDesign(this.documentService.findProjectById(result.design.projectId), result.design);
                        this.routingService.navigateToUrl(UrlPath.main + documentDesign.id);
                    });
                return result;
            });
    }
    public async newDesignFromTemplate(designId: string, isEntryPointSplitted: boolean, projectId?: string, designName?: string) {
        try {
            const template = await this.designTemplateService.getById(designId);
            const projectDesign = convertStringToLatestDesign(template.ProjectDesign);
            const designType = this.getDesignTypes.find(x => x.id == DesignTypeId.Concrete2Concrete);

            projectDesign.designName = designName ?? this.documentService.createUniqueName(
                this.createDesignName(this.localization, designType, projectDesign.options.connectionType == ConnectionTypeId.Splices && isEntryPointSplitted),
                Object.values(this.documentService.draftsProject.designs ?? []).map((item) => item.designName));

            if (projectId) {
                projectDesign.projectName = this.documentService.findProjectById(projectId)?.name as string;
            }

            const design = await this.calculationService.createAndOpenFromProjectDesignC2C(projectDesign, projectId ?? this.documentService.draftsProject.id as string, designId);

            const project = this.documentService.findProjectById(projectId ?? this.documentService.draftsProject.id as string);
            this.userService.changeDesign(project, design);
            this.routingService.navigateToUrl(UrlPath.main + design.id);

            return {
                designId: design.id,
                path: UrlPath.main + design.id,
                design,
                success: true
            } as ISaveDesignResult;

        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }
            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public async openTemplate(designId: string) {
        try {
            const template = await this.designTemplateService.getById(designId);
            const projectDesign = convertStringToLatestDesign(template.ProjectDesign);
            const design = await this.calculationService.createAndOpenTemplateC2C(projectDesign, template.DesignTemplateDocumentId, template.DesignTemplateName);

            this.userService.changeDesign(undefined, design);
            this.routingService.navigateToUrl(UrlPath.main + design.id);
            this.trackingService.trackOnTemplateOpen(
                design.templateId as string,
                design.designData.projectDesignC2C.options.designStandard,
                design.designData.projectDesignC2C.options.connectionType);

            return {
                designId: design.id,
                path: UrlPath.main + design.id,
                design,
                success: true
            } as ISaveDesignResult;

        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }

            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public toDisplayDesignTemplate(template: DesignTemplateEntity, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = this.getRegionById(template.RegionId);
        const designType = (this.codelist.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignType[])
            .find(x => x.id == template.DesignTypeId);
        const designStandard = (this.codelist.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[])
            .find(x => x.id == template.DesignStandardId) as DesignStandard;

        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(region, designStandard, template.ApprovalNumber, designType?.id);
        const designStandardTextApprovalNumberText = this.createDesignStandardApprovalNumberText(template.ApprovalNumber, designStandard, designType?.id);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localization.moment(template.DateCreate).format('ll'),
            projectName: designType?.getTranslatedNameText(codeListDeps) ?? '',
            productName: template.AnchorName,
            designType: template.DesignTypeId,
            approvalNumber: template.ApprovalNumber,
            region: region as CommonRegion,
            designStandardC2C: designStandard,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(template.DesignTemplateDocumentId) : undefined,
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            isSharedByMe: template.isSharedByMe
        } as IDisplayDesign;
    }

    public toDisplayDesign(design: IDesignListItem, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = this.getRegionById(design.metaData?.region) as Region;
        const designMethodGroup = (this.codelist.projectCodeListsC2C[ProjectCodeList.DesignMethodGroupsC2C] as DesignMethodGroup[])
            .find(dMeth => dMeth.id == design.metaData?.designMethod) as DesignMethodGroup;

        const productName = design.metaData?.productName;
        const designType = design.metaData?.designType;
        const approvalNumber = design.metaData?.approvalNumber;
        const parentProject = this.documentService.findProjectById(design.projectId);

        const designStandard = (this.codelist.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C] as DesignStandard[])
            .find(x => x.id == design.metaData.standard) as DesignStandard;

        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(region, designStandard, approvalNumber, designType);
        const designStandardTextApprovalNumberText = this.createDesignStandardApprovalNumberText(approvalNumber, designStandard, designType);
        const utcTime  = this.localization.moment(design.createDate).utc(true).toDate();
        const localCreatedDateTime = this.localization.moment(utcTime).format('MMM D, YYYY h:mm A');
        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? localCreatedDateTime : this.localization.moment(design.createDate).format('LL'),
            rawProject: parentProject,
            projectId: design.projectId,
            productName: productName ?? '',
            designType,
            approvalNumber: approvalNumber ?? '',
            region,
            designStandardC2C: designStandard,
            designMethodGroupC2C: designMethodGroup,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(design.id) : undefined,
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            isFavorite: design.isFavorite ?? false,
            isSharedByMe: design.isSharedByMe ?? false
        };
    }

    public createRegionDesignStandardApprovalNumber(region: Region | undefined, designStandard: DesignStandard, approvalNumber?: string, designTypeId?: DesignTypeId) {
        if (region?.id == SpecialRegion.Default) {
            region = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        }

        const designType = this.getDesignTypes.find(x => x.id == designTypeId);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        const designStandardMod = getUKDesignStandardKey(region?.id ?? 0, designStandard.cloneDeep(), this.featureVisibilityService.isFeatureEnabled('C2C_UKTA'));

        const text = [
            region != undefined ? region.getTranslatedNameText(codeListDeps) : undefined,
            designStandard != null ? this.localization.getString(this.getDesignTypeSpecificKey(designStandardMod.nameResourceKey, designType) ?? '') : undefined,
            approvalNumber
        ].filter((value) => value != undefined && value != '').join(', ');

        if (text == null || text == '') {
            return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    private get getDesignTypes() {
        return this.codelist.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C];
    }

    private createDesignName(localization: LocalizationService, designType?: CodeList, isEntryPointSplittedPIR = false) {
        const translatedNameText = isEntryPointSplittedPIR
            ? localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignTitle.Concrete2ConcretePirEU')
            : designType?.getTranslatedNameText(getCodeListTextDeps(localization));

        return `${translatedNameText} - ${localization.moment(new Date()).format('ll')}`;
    }

    private getRegionById(regionId: number | null) {
        const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private createDesignStandardApprovalNumberText(approvalNumber?: string, designStandard?: DesignStandard, designTypeId?: number) {
        const designType = (this.codelist.projectCodeListsC2C[ProjectCodeList.DesignTypeC2C] as DesignType[]).find(x => x.id == designTypeId);
        const text = [
            designStandard != undefined ? this.localization.getString(this.getDesignTypeSpecificKey(designStandard.nameResourceKey, designType) ?? '') : undefined,
            approvalNumber
        ].filter((value) => value != undefined && value != '').join(', ');

        if (text == undefined || text == '') {
            return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    private createRegionText(region?: Region) {
        if (region?.id == SpecialRegion.Default) {
            region = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = region?.getTranslatedNameText(codeListDeps) ?? '';

        return text == '' ? this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown') : text;
    }

    private getDesignTypeSpecificKey(key?: string, designType?: DesignType) {
        if (designType == undefined) {
            return key;
        }

        const newKey = `${key}.${designType.displayKey}`;
        return this.localization.getKeyExists(newKey) ? newKey : key;
    }
}
